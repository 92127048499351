/** 3rd-party */
import { debounce } from 'lodash';
import Vue from 'vue';
import { NOTIFICATIONS_PANEL_ID } from '@/lib/variables/panels';
import { NOTIFICATIONS_LAYER } from '../lib/constants/layers';

export default {
	setNotificationsLayerConstants(state, value) {
		state.layers.notificationsLayer = value;
		state.layers.layerNamesKpisArea = [value];
		state.layers.layerNamesKpisDatasourcetype = [value];
	},

	/** TABS */
	setActivePanel(state, options) {
		state.mappanel.activePanel = options.panelId;
		state.mappanel.activePanelOptions = options.identifier ? options : null;
	},

	clearDynamicPanels(state) {
		state.mappanel.dynamicPanels = [];
	},

	addDynamicPanel(state, options) {
		const index = state.mappanel.dynamicPanels.findIndex(({ panelId }) => panelId === options.panelId);
		const exist = index >= 0;

		if (!exist) {
			state.mappanel.dynamicPanels = [...state.mappanel.dynamicPanels, options];
		}
	},

	removeDynamicPanelById(state, panelId) {
		// obtener los que se quedan
		const panels = state.mappanel.dynamicPanels.filter((options) => options.panelId !== panelId);
		state.mappanel.dynamicPanels = panels;

		if (state.mappanel.dynamicPanels.length === 0) {
			state.mappanel.activePanel = NOTIFICATIONS_PANEL_ID;
			state.mappanel.activePanelOptions = null;
		} else {
			const lastElement = state.mappanel.dynamicPanels[state.mappanel.dynamicPanels.length - 1];
			state.mappanel.activePanel = lastElement.panelId;
			state.mappanel.activePanelOptions = lastElement;
		}
	},

	/** MAP */
	setMapLegendExpanded(state, expanded) {
		state.map.legendExpanded = expanded;
	},
	setMapLegendExpandedCompareMap(state, expanded) {
		state.mapCompare.legendExpanded = expanded;
	},

	setMapZoom(state, zoom) {
		state.mappanel.zoom = zoom;
	},

	setMapBbox(state, bbox) {
		setDelayedBbox(state, bbox);
	},

	setRasterOpacity(state, opacity) {
		state.mappanel.rasterOpacity = opacity;
	},

	setRasterOpacityCompareMap1(state, opacity) {
		state.mappanel.rasterOpacityCompareMap1 = opacity;
	},

	setRasterOpacityCompareMap2(state, opacity) {
		state.mappanel.rasterOpacityCompareMap2 = opacity;
	},

	setMapLoaded(state, value) {
		state.map.mapLoaded = value;
	},
	setMapCompareLoaded(state, value) {
		state.mapCompare.mapLoaded = value;
	},
	setMapLoadedLayersFinish(state, value) {
		state.map.mapLoadedLayersFinish = value;
	},
	/*setClickMap(state, value) {
		state.map.eventClick = value;
	},
	setClickMapCompare(state, value) {
		state.mapCompare.eventClick = value;
	},*/

	setRasterLayersSettings(state, value) {
		state.mappanel.rasterLayersSettings = value;
	},
	setRasterLayersCompareMap1(state, value) {
		state.mappanel.rasterLayersCompareMap1 = value;
	},

	setRasterLayersCompareMap2(state, value) {
		state.mappanel.rasterLayersCompareMap2 = value;
	},

	setRasterLayersData(state, value) {
		state.mappanel.rasterLayersData = value;
	},

	setUserProperties(state, value) {
		state.user.properties = value;
	},

	setAreas(state, value) {
		state.areas.properties = value;
	},

	setDatasourcetype(state, value) {
		state.datasourcetype.properties = value;
	},

	setLayers(state, value) {
		state.mappanel.layers = value;
	},

	setExternalLayers(state, value) {
		state.mappanel.externalLayers = value;
	},

	setGeoJsonLayers(state, value) {
		state.mappanel.geoJsonLayers = value;
	},
	setGeoJsonLayersData(state, value) {
		state.mappanel.geoJsonLayersData = value;
	},
	// Panel Settings
	setIsRasterActive(state, value) {
		state.settingsPanel.isRasterActive = value;
	},
	setActiveRasterLayer(state, value) {
		state.settingsPanel.activeRasterLayer = value;
	},

	// Panel Compare Map
	setIsRasterCompareMap1Active(state, value) {
		state.compareMapPanel.isRasterCompareMap1Active = value;
	},
	setActiveCompareMap1RasterLayer(state, value) {
		state.compareMapPanel.activeCompareMap1RasterLayer = value;
	},

	setIsRasterCompareMap2Active(state, value) {
		state.compareMapPanel.isRasterCompareMap2Active = value;
	},
	setActiveCompareMap2RasterLayer(state, value) {
		state.compareMapPanel.activeCompareMap2RasterLayer = value;
	},

	// Panel Settings
	setActiveGeoJsonLayer(state, value) {
		state.settingsPanel.activeGeoJsonLayer = value;
	},
	disableRasterLayer(state, value) {
		state.mappanel.rasterLayersSettings[value]['active'] = false;
	},

	setDisabledItemLayer(state, { idLayer, disabled }) {
		state.mappanel.layers[idLayer].disabledItem = disabled;
	},

	addLoadingProcess(state) {
		state.mappanel.loading++;
	},

	removeLoadingProcess(state) {
		state.mappanel.loading--;

		if (state.mappanel.loading < 0) {
			state.mappanel.loading = 0;
		}
	},

	resetLoadingProcess(state) {
		state.mappanel.loading = 0;
	},

	setFeatures(state, data) {
		const { identifier, features } = data;
		state.data[identifier].viewportFeatures = features;
	},
	/* setFeatures(state, data) {
		state.data.notifications.viewportFeatures = data;
	}, */
	setNotifications(state, data) {
		state.data.notificationData = data;
	},

	setNotificationsLayerData(state, payload) {
		const { index, data } = payload;
		state.data[index] = data;
	},

	setNotificationsFeatures(state, data) {
		state.data[NOTIFICATIONS_LAYER].viewportFeatures = data;
	},

	resetNotificationsData(state) {
		state.data[NOTIFICATIONS_LAYER] = {
			viewportFeatures: [],
			statusHistogram_byArea: {},
			statusHistogram_byDatasourcetype: {},
			counts_byArea: {
				global: 0,
				viewport: 0
			},
			counts_byDatasourcetype: {
				global: 0,
				viewport: 0
			},
			limit: 100,
			offset: 0
		};
	},

	/** KPI's */

	setHistogram_byArea(state, data) {
		const { identifier, statusHistogram_byArea } = data;
		state.data[identifier].statusHistogram_byArea = statusHistogram_byArea;
	},

	setHistogram_byDatasourcetype(state, data) {
		const { identifier, statusHistogram_byDatasourcetype } = data;
		state.data[identifier].statusHistogram_byDatasourcetype = statusHistogram_byDatasourcetype;
	},

	setTotals_byArea(state, { identifier, totals }) {
		Vue.set(state.data[identifier].counts_byArea, 'global', totals);
	},

	setTotals_byDatasourcetype(state, { identifier, totals }) {
		Vue.set(state.data[identifier].counts_byDatasourcetype, 'global', totals);
	},

	setCounts_byArea(state, { identifier, viewport }) {
		Vue.set(state.data[identifier].counts_byArea, 'viewport', viewport);
	},

	setCounts_byDatasourcetype(state, { identifier, viewport }) {
		Vue.set(state.data[identifier].counts_byDatasourcetype, 'viewport', viewport);
	},

	setNotificationsKpiSelected_byArea(state, obj) {
		state.mappanel.notifications_byArea.selectedKpi = obj.key;

		if (state.mappanel.kpisNotificationsFilters.selected) {
			state.mappanel.kpisNotificationsFilters.selected.statusNotifications = obj.status;
			return;
		}

		if (obj.key) {
			state.mappanel.kpisNotificationsFilters.selected = { statusNotifications: obj.status };
		}
	},

	setNotificationsKpiSelected_byDatasourcetype(state, obj) {
		state.mappanel.notifications_byDatasourcetype.selectedKpi = obj.key;

		if (state.mappanel.kpisNotificationsFilters.selected) {
			state.mappanel.kpisNotificationsFilters.selected.statusNotifications = obj.status;
			return;
		}

		if (obj.key) {
			state.mappanel.kpisNotificationsFilters.selected = { statusNotifications: obj.status };
		}
	},

	// Set offset
	setOffset(state, { identifier, offset }) {
		state.data[identifier].offset = offset;
	},

	// Refresh Time (Min. delivery period)
	setRefreshTime(state, value) {
		state.mappanel.refreshTime = value;
	},

	// Icons ORG
	setIconsOrg(state, values) {
		state.selectData.iconsOrg = values;
	},
	// Icons DEV
	setIconsDev(state, values) {
		state.selectData.iconsDev = values;
	},
	// Icons DTS
	setIconsDts(state, values) {
		state.selectData.iconsDts = values;
	},
	// Icons APP
	setIconsApp(state, values) {
		state.selectData.iconsApp = values;
	},

	// Icons
	setIcons(state, values) {
		state.iconsData.icons = values;
	},

	// Devices
	setDevices(state, values) {
		state.mappanel.devices = values;
	},
	setDevicesData(state, values) {
		state.mappanel.devicesData = values;
	},
	setHasDevices(state, value) {
		state.mappanel.hasDevices = value;
	},
	setDeviceZoom(state, values) {
		state.mappanel.deviceZoom = values;
	},

	// Aqi Points
	setAqiPoints(state, values) {
		state.mappanel.aqipoints = values;
	},
	setHasAqiPoints(state, value) {
		state.mappanel.hasAqiPoints = value;
	},

	// Spatial Tables (Layers)
	setHasLayers(state, value) {
		state.mappanel.hasLayers = value;
	},
	// External Layers
	setHasExternalLayers(state, value) {
		state.mappanel.hasExternalLayers = value;
	},
	// Parameters
	setParameters(state, values) {
		state.mappanel.parameters = values;
	},
	setParameterStatus(state, values) {
		state.mappanel.parameterStatus = values;
	},
	// SLA Dashboard
	setSlaDevices(state, value) {
		state.dashboard.slaDevices = value;
	},
	setDashboardTab(state, value) {
		state.dashboard.dashboardTab = value;
	},
	setContentTab(state, value) {
		state.dashboard.contentTab = value;
	},
	setSlaChartFilter(state, value) {
		state.dashboard.slaChartFilter = value;
	},
	// Close/Open all Map Panels
	setPanelsClosed(state, value) {
		Object.keys(state.settingsPanel.isOpened).forEach((k) => (state.settingsPanel.isOpened[k] = value));
		Object.keys(state.devicesPanel.isOpened).forEach((k) => (state.settingsPanel.isOpened[k] = value));
		Object.keys(state.compareMapPanel.isOpened).forEach((k) => (state.compareMapPanel.isOpened[k] = value));
	},

	// Application
	setApplication(state, values) {
		state.data.application = values;
	},
	// URL Base for Icons
	setUrlBaseIcons(state, values) {
		state.iconsData.urlBaseIcons = values;
	},

	// MAP FEATURE CARD
	setFeatureClick(state, value) {
		state.mappanel.featureCard.featureClick = value;
	},
	setFeaturePopupClick(state, value) {
		state.mappanel.featureCard.featurePopupClick = value;
	},
	setFeatureType(state, value) {
		state.mappanel.featureCard.featureType = value;
	},
	setDisplayMode(state, value) {
		state.mappanel.featureCard.displayMode = value;
	},

	setSelectedTabDevice(state, value) {
		state.mappanel.featureCard.selectedTabDevice = value;
	},
	setSelectedTabHistoric(state, value) {
		state.mappanel.featureCard.selectedTabHistoric = value;
	},
	setSelectedTabAdvanced(state, value) {
		state.mappanel.featureCard.selectedTabAdvanced = value;
	},
	setSelectedTabPrediction(state, value) {
		state.mappanel.featureCard.selectedTabPrediction = value;
	},
	setSelectedTabNotification(state, value) {
		state.mappanel.featureCard.selectedTabNotification = value;
	},
	setSelectedTabNotificationDialog(state, value) {
		state.mappanel.featureCard.selectedTabNotificationDialog = value;
	},
	setHasHistoricNotifications(state, value) {
		state.mappanel.featureCard.hasHistoricNotifications = value;
	},
	// FORM HISTORIC
	setFormHistoricObject(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index] = data;
	},
	cleanFormHistoric(state) {
		state.mappanel.featureCard.formHistoric = [];
		state.elastic.historicData = [];
	},
	cleanFormAdvancedChart(state) {
		state.mappanel.featureCard.formAdvancedDatasource = [];
		state.elastic.advancedDatasourceData = [];
	},

	// ADVANCED CHART
	setFormAdvancedDatasourceObject(state, { index, data }) {
		state.mappanel.featureCard.formAdvancedDatasource[index] = data;
	},
	setFormAdvancedObject(state, payload) {
		state.mappanel.featureCard.formAdvanced = payload;
	},
	// Predictions CHART
	setFormPredictionsObject(state, payload) {
		state.mappanel.featureCard.formPredictions = payload;
	},

	// XAI CHART
	setFormXAIObject(state, payload) {
		state.mappanel.featureCard.formXAI = payload;
	},

	// COMPARE AQI
	setFormCompareAqiObject(state, data) {
		state.mappanel.featureCard.formCompareAqi = data;
	},
	cleanFormCompareAqi(state) {
		state.mappanel.featureCard.formCompareAqi = null;
	},
	cleanCompareAqiElasticData(state) {
		state.elastic.compareAqiData = null;
	},

	// FORM HISTORIC INPUTS

	setDateInterval(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].dateInterval = data;
	},
	setStartDate(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].startDate = data;
	},
	setEndDate(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].endDate = data;
	},
	setFormDisabled(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].formDisabled = data;
	},
	setStartDateDisabled(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].startDateDisabled = data;
	},
	setEndDateDisabled(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].endDateDisabled = data;
	},
	setChartData(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].chartData = data;
	},
	setDesignOption(state, { index, data }) {
		state.mappanel.featureCard.formHistoric[index].designOption = data;
	},
	setAqiDescriptions(state, value) {
		state.mappanel.featureCard.aqiDescriptions = value;
	},
	// ELASTIC
	setPopupElasticData(state, value) {
		state.elastic.popupData = value;
	},
	setLoadingPopup(state, value) {
		state.elastic.loadingPopup = value;
	},
	setRealTimeElasticData(state, value) {
		state.elastic.realTimeData = value;
	},
	setHistoricElasticData(state, payload) {
		const { index, data } = payload;
		state.elastic.historicData[index] = data;
	},
	setPredictionElasticData(state, payload) {
		const { index, data } = payload;
		state.elastic.predictionData[index] = data;
	},
	setAdvancedHistoricElasticData(state, payload) {
		state.elastic.advancedHistoricData = payload;
	},
	setAdvancedDatasourceElasticData(state, payload) {
		state.elastic.advancedDatasourceData = payload;
	},
	setCompareAqiElasticData(state, data) {
		state.elastic.compareAqiData = data;
	},
	// ELASTIC DATE RANGES
	setHistoricFirstDates(state, value) {
		state.elastic.historicFirstDates = value;
	},
	setHistoricLastDates(state, value) {
		state.elastic.historicLastDates = value;
	},

	setPredictionFirstDates(state, value) {
		state.elastic.predictionFirstDates = value;
	},
	setPredictionLastDates(state, value) {
		state.elastic.predictionLastDates = value;
	},

	setRules(state, value) {
		state.data.rules = value;
	},
	setTimeunits(state, value) {
		state.data.timeunits = value;
	},
	setErrorCodes(state, value) {
		state.data.errorCodes = value;
	},

	setFormFilters(state, value) {
		state.form.formFilters = value;
	},
	setAreaDefinitions(state, value) {
		state.form.areaDefinitions = value;
	},
	setSeverities(state, value) {
		state.data.severities = value;
	},
	setAllParameters(state, value) {
		state.data.parameters = value;
	},
	setFilterData(state, value) {
		state.form.filterData = value;
	},
	// MISC
	setCollapsedInfo(state, value) {
		state.misc.collapsedInfo = value;
	},
	setCenterCoordinates(state, value) {
		state.map.centerCoordinates = value;
	},
	setStatusFilters(state, value) {
		state.form.statusFilters = value;
	}
};

const setDelayedBbox = debounce((state, bbox) => {
	normalizedBoundingBox(state, bbox);
}, 500);

const normalizedBoundingBox = (state, bbox) => {
	const [xmin, ymin, xmax, ymax] = bbox;
	// TODO: No es correcto los valores para xmin y xmax. Hay que hacer una resta segun te mueves en longitud para tener siempre valores entre -180 y 180
	//console.log([xmin < -180 || xmax > 180 ? -180 : xmin, ymin < -90 ? -90 : ymin, xmax > 180 || xmin < -180 ? 180 : xmax, ymax > 90 ? 90 : ymax]);
	//state.mappanel.bbox = [xmin < -180 || xmax > 180 ? -180 : xmin, ymin < -90 ? -90 : ymin, xmax > 180 || xmin < -180 ? 180 : xmax, ymax > 90 ? 90 : ymax];

	// TODO: No es correcto los valores para xmin y xmax. Hay que hacer una resta segun te mueves en longitud para tener siempre valores entre -180 y 180
	//console.log([ymin < -90 ? -90 : ymin, xmax > 180 ? 180 : xmax, ymax > 90 ? 90 : ymax, xmin < -180 ? -180 : xmin]);
	state.mappanel.bbox = [ymin < -90 ? -90 : ymin, xmax > 180 ? 180 : xmax, ymax > 90 ? 90 : ymax, xmin < -180 ? -180 : xmin];
};
